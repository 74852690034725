/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
} from '@sentry/remix'
import { StrictMode, startTransition, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

init({
  dsn: window.__remixContext.state.loaderData?.root?.sentry_dsn ?? 'missing',
  environment:
    window.__remixContext.state.loaderData?.root?.env === 'prod'
      ? 'production'
      : 'development',

  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    browserProfilingIntegration(),
  ],

  tracesSampleRate: 0.5,
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
